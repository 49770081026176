import { FC, useMemo } from 'react';
import { CustomizationFlagStatus } from './customization-flag-status';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from 'redux/actions/location/current-location';
import { useCustomizationFlagAuditLogs } from '../../../../components/customization-page-container/customization-page.queries';
import { Table, TableColumnConfig } from '@weave/design-system';
import { LocationFeatureHistoryEvent } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { formatDate } from 'helpers/date';
import { theme } from '@weave/theme';

export const CustomizationFlagAuditEvents: FC<{
  featureId?: string;
}> = ({ featureId }) => {
  const locationId = useSelector(selectCurrentLocationId);
  const { data, isLoading } = useCustomizationFlagAuditLogs({
    locationId,
    featureId,
  });

  const colConfig = useMemo<TableColumnConfig<LocationFeatureHistoryEvent>[]>(
    () => [
      {
        Header: 'Modified On',
        accessor: ({ changedAt }) => formatDate(changedAt, 'llll', true),
        id: 'modifiedAt',
        minWidth: 200,
      },
      {
        Header: 'User',
        accessor: 'changedBy',
        id: 'changedBy',
        minWidth: 350,
      },
      {
        Header: 'Operation',
        accessor: 'operation',
        id: 'operation',
        width: 120,
      },
      {
        Header: 'Status',
        accessor: ({ changedTo }) =>
          changedTo ? <CustomizationFlagStatus currentState={changedTo} /> : null,
        id: 'status',
        width: 150,
      },
      {
        Header: 'Quota',
        accessor: 'quota',
        id: 'quota',
        width: 150,
      },
    ],
    []
  );

  return (
    <div
      css={{
        border: `1px solid ${theme.colors.neutral20}`,
        margin: theme.spacing(2),
        width: '100%',
      }}
    >
      <Table isLoading={isLoading} data={data?.events ?? []} colConfig={colConfig} />
    </div>
  );
};
