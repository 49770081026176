import { CustomAxios, getResponseData } from '../../redux/axios';
import { DashboardSubmission } from './dashboard.types';
import {
  ProcessRequest,
  ProcessResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/dashboard-submission/v1/dashboard_submission_service.pb';

const SCHEMA_PORTING_DASHBOARD_PREFIX_URL = '/phone/porting/dashboard-submission/v1';

const postSchemaDashboardSubmission = (body: ProcessRequest): Promise<ProcessResponse> =>
  CustomAxios.post(
    `${SCHEMA_PORTING_DASHBOARD_PREFIX_URL}/portin/dashboardsubmission`,
    body
  ).then(getResponseData);

export const postDashboardSubmission = (
  body: DashboardSubmission
): Promise<DashboardSubmission> =>
  CustomAxios.post('/support/v1/portingdata/dashboardsubmission', body).then(
    getResponseData
  );

export const dashboardApi = {
  postDashboardSubmission,
};

export const PortingDashboardSchemaAPI = {
  postDashboardSubmission: postSchemaDashboardSubmission,
};
