import { css } from '@emotion/core';
import {
  PrimaryButton,
  TextField,
  useForm,
  useModalControl,
  ValidatorFieldState,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PortingDashboardSchemaAPI } from '../../../apis/porting/dashboard.api';
import { selectCurrentLocation } from '../../../redux/actions/location/current-location';
import { getParsedErrorMessage } from '../../../redux/axios';
import { LoaderModal as Loader } from '../utils/loader.component';
import { MessageDialogue } from '../utils/message-dialogue.component';

const supportKeyRegex = /sr_[A-Za-z\d]{6}\b/;

interface Props {
  getPortingData: () => void;
}

type MessageModal = {
  header: string;
  body: string;
  success: boolean;
};

export const SalesforcePortOrderForm = ({ getPortingData }: Props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [submissionPortModal, setSubmissionPortModal] = useState<MessageModal>({
    header: 'Successfully submitted',
    body: 'Port order successfully imported from salesforce',
    success: true,
  });
  const currentLocation = useSelector(selectCurrentLocation);
  const {
    modalProps: messageModalProps,
    closeModal: closeMessageModal,
    triggerProps: messageTriggerModalProps,
  } = useModalControl();

  useEffect(() => {
    initValues();
    return () => {};
  }, []);

  const initValues = () => {
    seedValues({
      salesforcePortOrderId: '',
      supportKey: '',
    });
  };

  const {
    formProps: createSalesforcePortOrderProps,
    getFieldProps,
    seedValues,
    isComplete: isSalesforcePortOrderFormComplete,
  } = useForm({
    fields: {
      salesforcePortOrderId: {
        type: 'text',
        required: true,
        validator: ({ value }: ValidatorFieldState<'text'>) =>
          value.length === 18 ? '' : 'Must be of 18 characters.',
      },
      supportKey: {
        type: 'text',
        validator: ({ value }: ValidatorFieldState<'text'>) => {
          if (value.trim() && !supportKeyRegex.test(value)) {
            return 'Must be of format: sr_{6characters}';
          }
          return '';
        },
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values: any) => {
    const error = await submitDashboardSubmission(values);
    if (error) {
      setSubmissionPortModal({
        header: 'Something Went Wrong',
        body: error,
        success: false,
      });
    } else {
      setSubmissionPortModal({
        header: 'Successfully submitted',
        body: 'Port order successfully imported from salesforce',
        success: true,
      });
    }
    messageTriggerModalProps.onClick();
  };

  const submitDashboardSubmission = async (values: any): Promise<string> => {
    const { salesforcePortOrderId, supportKey } = values;

    setShowLoader(true);

    try {
      await PortingDashboardSchemaAPI.postDashboardSubmission({
        salesforcePortOrderId,
        supportKey,
        locationId: currentLocation?.LocationID,
      });
      return '';
    } catch (error) {
      console.error(error);
      return getParsedErrorMessage(error);
    } finally {
      setShowLoader(false);
    }
  };

  const closeModal = () => {
    closeMessageModal();
    if (submissionPortModal.success) {
      getPortingData();
    }
  };

  return (
    <>
      <form {...createSalesforcePortOrderProps}>
        <div
          css={css`
            display: flex;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              width: 213px;
              margin-right: ${theme.spacing(1.2)};
            `}
          >
            <TextField
              {...getFieldProps('salesforcePortOrderId')}
              name="salesforcePortOrderId"
              label="Salesforce PO id"
              helperText="Ex: a0X8H000000942BUAQ"
            />
          </div>

          <div
            css={css`
              width: 140px;
              margin-right: ${theme.spacing(1.2)};
            `}
          >
            <TextField
              {...getFieldProps('supportKey')}
              name="supportKey"
              label="Support Key"
              helperText="Ex: sr_ab1234"
            />
          </div>
        </div>
        <div
          css={css`
            width: 160px;
            margin: 0 auto;
          `}
        >
          <PrimaryButton
            color="blue"
            size="small"
            type="submit"
            disabled={!isSalesforcePortOrderFormComplete}
            css={submitButtonStyles}
          >
            Submit
          </PrimaryButton>
        </div>
      </form>

      <Loader showLoading={showLoader} message="Submitting..." />

      <MessageDialogue
        header={submissionPortModal.header}
        body={submissionPortModal.body}
        messageModalProps={messageModalProps}
        closeMessageModal={closeModal}
        actionLabel="Okay"
        onActionLabelClick={closeModal}
      />
    </>
  );
};

const submitButtonStyles = css`
  margin: ${theme.spacing(3, 0)};
`;
