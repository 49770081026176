import React from 'react';
import { findIndex } from 'lodash';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { Text } from '@weave/design-system';
import { LocationFeatureStates } from '../customization-page-v2.constant';
import { CustomizationFlagStatesV2 } from 'models/location-feature.model';
import { ToggleState } from 'components/shared/multi-state-toggle/multi-state-toggle.component';
import {
  getCustomizationFlagStateBackgroundColor,
  getCustomizationFlagStateColor,
  getCustomizationFlagLabelByState,
} from '../customization-page.utils';
import { customizationPageTestIds } from '../customization-page-test-ids';

interface Props {
  currentState: CustomizationFlagStatesV2;
  isDisabled?: boolean;
  onCustomizationToggle?: (newState: ToggleState) => void;
}

export const CustomizationFlagStatus = ({
  currentState,
  isDisabled = false,
  onCustomizationToggle,
}: Props) => {
  const getCurrentState = (currentState: CustomizationFlagStatesV2) => {
    return (
      LocationFeatureStates.find((state) => state.key === currentState) ||
      LocationFeatureStates[0]
    );
  };

  const toggleState = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isDisabled && onCustomizationToggle) {
      const currentStateFeature = getCurrentState(currentState);

      let index = findIndex(
        LocationFeatureStates,
        (state) => state.key === currentStateFeature.key
      );

      index = index < LocationFeatureStates.length - 1 ? index + 1 : 0;
      onCustomizationToggle(LocationFeatureStates[index]);
    }
  };

  return (
    <>
      <div
        css={[
          stateStyles(isDisabled),
          { cursor: onCustomizationToggle ? 'pointer' : 'default' },
          getCustomizationFlagStateBackgroundColor(currentState),
        ]}
        onClick={toggleState}
      >
        <Text
          size="medium"
          color={getCustomizationFlagStateColor(currentState)}
          css={textStyles}
          data-testid={customizationPageTestIds.customizationFlagStatus(currentState)}
        >
          {getCustomizationFlagLabelByState(currentState)}
        </Text>
      </div>
    </>
  );
};

const stateStyles = (isDisabled: boolean) => css`
  text-align: center;
  width: 80px;
  border-radius: ${theme.borderRadius.small};
  opacity: ${isDisabled ? 0.5 : 1};
`;

const textStyles = css`
  padding: ${theme.spacing(1, 1.5)};
  margin: 0;
`;
