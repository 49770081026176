import { css } from '@emotion/core';
import { InfoBadgeIcon, Text, TextLink } from '@weave/design-system';
import { theme } from '@weave/theme';
import {
  TrialSubscriptionQueries,
  TrialSubscriptionTypes,
} from 'apis/trial-subscription';
import React, { FC, useMemo, useState } from 'react';

import { formatDate } from 'helpers/date';
import { FeatureEnumToNameMap } from 'constants/feature-data';

interface TrialActiveBannerProps {
  locationId: string;
}

interface TrialStateExtended extends TrialSubscriptionTypes.TrialState {
  featureName: string;
  formattedEndDate: string;
}

const COMPACT_LIST_SIZE = 2;

export const TrialActiveBanner: FC<TrialActiveBannerProps> = ({ locationId }) => {
  const { data: trialHistoryData, isLoading } =
    TrialSubscriptionQueries.useGetTrialHistory({ locationId });

  const [showFullList, setShowFullList] = useState(false);

  const trialFeaturesList = useMemo<TrialStateExtended[]>(() => {
    return [
      ...(trialHistoryData?.trialState?.FREE_TRIAL_ABOUT_TO_EXPIRE?.features ?? []),
      ...(trialHistoryData?.trialState?.FREE_TRIAL_ACTIVE?.features ?? []),
    ].map((trialData) => ({
      ...trialData,
      featureName: trialData.featureEnum
        ? FeatureEnumToNameMap[trialData.featureEnum] ?? trialData.featureEnum
        : '',
      formattedEndDate: formatDate(trialData.endDate, 'MMMM DD'),
    }));
  }, [trialHistoryData]);

  if (isLoading || !trialFeaturesList?.length) {
    return null;
  }
  return (
    <div css={alertBannerStyles}>
      <InfoBadgeIcon color="warn" />
      <Text size="medium" css={{ margin: 0 }}>
        {trialFeaturesList.length > 1 ? (
          <>
            Location is on active trial(s) for the following features:
            <ul css={{ margin: 0 }}>
              {trialFeaturesList
                .slice(0, showFullList ? undefined : COMPACT_LIST_SIZE)
                .map(({ featureName, formattedEndDate }, index) => (
                  <li key={index}>
                    {featureName} (expires on {formattedEndDate})
                  </li>
                ))}
            </ul>
            {trialFeaturesList.length > COMPACT_LIST_SIZE && (
              <TextLink size="medium" onClick={() => setShowFullList((state) => !state)}>
                {showFullList ? 'Show less' : 'Show more'}
              </TextLink>
            )}
          </>
        ) : (
          `Location is on active trial for ${trialFeaturesList[0]?.featureName}. Trial will expire on ${trialFeaturesList[0]?.formattedEndDate}`
        )}
      </Text>
    </div>
  );
};

const alertBannerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.colors.warning50}`,
  borderRadius: theme.borderRadius.medium,
  backgroundColor: theme.colors.warning5,
});
