import { CustomAxios, getResponseData } from '../../redux/axios';
import { ResumeStripePayoutAccountResponse } from './resume-stripe-payout.types';

export const resumeStripePayoutAccountApi = {
  baseUrl: 'commands/service/account',
  async resumeStripePayoutAccount(
    paymentsURL?: string,
    stripeAccountId?: string,
    locationId?: string
  ) {
    if (!paymentsURL || !stripeAccountId || !locationId) {
      throw new Error('Invalid arguments');
    }
    const response = await CustomAxios.patch(
      `${paymentsURL}/${this.baseUrl}/${stripeAccountId}/enable-payouts/true`,
      { locationId }
    );
    return getResponseData<ResumeStripePayoutAccountResponse>(response);
  },
};
