import { State as CustomizationFlagStatesV2 } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';

export { State as CustomizationFlagStatesV2 } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
export interface LocationFeature {
  id: string;
  name: string;
  description: string;
  state: FeatureStates;
}

export enum FeatureStates {
  Hide = 'hide',
  Promote = 'promote',
  Active = 'active',
  Trial = 'trial',
}
export interface LocationFeatureV2 {
  id: string;
  name: string;
  description: string;
  featureEnum: Feature;
  state: CustomizationFlagStatesV2;
  isFreeTrial: boolean;
  quota: string;
}
