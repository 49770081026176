import { useSelector } from 'react-redux';
import { useAlert } from '@weave/alert-system';
import { CustomAxios, getResponseData } from 'redux/axios';
import { UseMutationResult, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { selectCurrentLocationId } from 'redux/actions/location';
import {
  CustomizationFlagsRequest,
  CustomizationFlagsResponse,
} from './customization-page-v2/customization-page-v2.types';
import {
  AccountBundleQuotaBySlugRequest,
  AccountBundleQuotaBySlugResponse,
  BlockPrice,
  BlockPriceByProductCodeResponse,
  Consumer,
} from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import {
  LocationFeatureHistoryRequest,
  LocationFeatureHistoryResponse,
} from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';

const GET_CUSTOMIZATION_FLAGS_BY_BUNDLES_URL = '/package-service/v1/customizationflags';

export const customizationFlagsQueryKeys = {
  base: ['customization-flags'] as const,
  customizationFlagsByBundles: (locationId: string) => [
    ...customizationFlagsQueryKeys.base,
    'bundles',
    locationId,
  ],
  getSFAccountBundle: (slug: string) => [
    ...customizationFlagsQueryKeys.base,
    'sf-account-bundle',
    slug,
  ],
  getQuotaList: (product: string) => [
    ...customizationFlagsQueryKeys.base,
    'quota-list',
    product,
  ],
  getAuditLogs: ({ locationId, featureId }: LocationFeatureHistoryRequest) => [
    ...customizationFlagsQueryKeys.base,
    'audit-logs',
    locationId,
    featureId,
  ],
};

const getQueryParamsByRequest = (request: CustomizationFlagsRequest) => {
  let queryParams = '';
  if (request.products) {
    request.products.forEach((productBundle) => {
      queryParams += `products=${productBundle}&`;
    });

    queryParams += `vertical=${request.vertical}`;
  }
  return queryParams;
};

const getCustomizationFlagsByBundles = (request: CustomizationFlagsRequest) => {
  let params = getQueryParamsByRequest(request);

  return CustomAxios.get(
    `${GET_CUSTOMIZATION_FLAGS_BY_BUNDLES_URL}?${params}`
  ).then<CustomizationFlagsResponse>(getResponseData);
};

export const useFetchCustomizationsFlagByBundles = (): UseMutationResult<
  CustomizationFlagsResponse,
  any,
  CustomizationFlagsRequest,
  any
> => {
  const locationId = useSelector(selectCurrentLocationId);
  const alert = useAlert();
  return useMutation(
    (request: CustomizationFlagsRequest) => getCustomizationFlagsByBundles(request),
    {
      mutationKey: customizationFlagsQueryKeys.customizationFlagsByBundles(locationId),
      retry: false,
      onError: () => {
        const errorMessage =
          'Something went wrong while fetching customization flag details for selected bundle';
        alert.error(errorMessage);
      },
    }
  );
};

const getSFAccountBundle = (
  request: AccountBundleQuotaBySlugRequest
): Promise<AccountBundleQuotaBySlugResponse> => {
  return CustomAxios.get('/salesforce/v1/account/bundle/quota', {
    params: {
      slug: request.slug,
      consumer: request.consumer || Consumer.INSYS,
    },
  }).then<AccountBundleQuotaBySlugResponse>(getResponseData);
};

export const useFetchSFAccountBundle = (
  request: AccountBundleQuotaBySlugRequest,
  options?: UseQueryOptions<AccountBundleQuotaBySlugResponse>
) => {
  return useQuery({
    queryKey: customizationFlagsQueryKeys.getSFAccountBundle(request.slug ?? ''),
    queryFn: () => getSFAccountBundle(request),
    retry: false,
    ...options,
    enabled: !!request.slug && !!(options?.enabled ?? true),
  });
};

export type SFQuotaProductCode = 'BULK-TEXTING' | 'EMAIL-MARKETING';

export const useGetQuotaList = (
  productCode: SFQuotaProductCode,
  options?: UseQueryOptions<BlockPriceByProductCodeResponse, unknown, BlockPrice[]>
) => {
  return useQuery({
    queryKey: customizationFlagsQueryKeys.getQuotaList(productCode),
    queryFn: async () =>
      await CustomAxios.get('/salesforce/v1/block-price', {
        params: {
          productCode,
          consumer: Consumer.INSYS,
        },
      }).then<BlockPriceByProductCodeResponse>(getResponseData),
    retry: false,
    ...options,
    enabled: productCode && (options?.enabled ?? true),
    select: (data) =>
      data?.blockPrices?.map((block) => ({
        ...block,
        upperBound: Math.round((block.upperBound || 0) / 1000) * 1000,
      })) ?? [],
  });
};

export const useCustomizationFlagAuditLogs = (
  request: LocationFeatureHistoryRequest,
  options?: UseQueryOptions<
    LocationFeatureHistoryResponse,
    unknown,
    LocationFeatureHistoryResponse
  >
) => {
  return useQuery({
    queryKey: customizationFlagsQueryKeys.getAuditLogs(request),
    queryFn: async () =>
      await CustomAxios.get(
        `/platform-location-feature/v1/location/${request.locationId}/history`,
        {
          params: {
            featureId: request.featureId,
          },
        }
      ).then(getResponseData),
    retry: false,
    refetchOnMount: true,
    ...options,
    enabled: !!request.locationId && !!request.featureId && (options?.enabled ?? true),
  });
};
