import { PrimaryButton, useModalControl } from '@weave/design-system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMerchant } from 'redux/actions/merchant/merchant.action';
import { useMerchant } from './use-merchant.hook';
import { resumeStripePayoutAccountApi } from '../../apis/payments/resume-stripe-payout.api';
import { useAlert } from '@weave/alert-system';
import { selectCurrentLocationId } from 'redux/actions/location';

export const ResumeStripePayoutBtn = () => {
  const alerts = useAlert();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const locationId = useSelector(selectCurrentLocationId);
  const { payoutsEnabled, activeProcessor, paymentsUrl } = useMerchant();

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      await resumeStripePayoutAccountApi.resumeStripePayoutAccount(
        paymentsUrl,
        activeProcessor?.stripeId,
        locationId
      );
      dispatch(getMerchant());
    } catch (error) {
      alerts.error('Error resuming payouts');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <PrimaryButton
        onClick={handleButtonClick}
        disabled={loading || payoutsEnabled}
        title="Resume stripe payout account for this location"
        style={{
          cursor: payoutsEnabled ? 'not-allowed' : 'pointer',
        }}
      >
        {loading ? 'Loading...' : 'Resume Payouts'}
      </PrimaryButton>
    </div>
  );
};
